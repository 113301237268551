import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";

export default function AddTask(props) {
  const baseURL = "https://taskongobackend.onrender.com/";
  const authtoken = localStorage.getItem("tog-auth-token");
  const [Loading, setLoading] = useState(false);
  const [titlev, setTitle] = useState("");
  const [datev, setDate] = useState("");
  const [statusv, setStatus] = useState(0);
  const [files, setFiles] = useState([]);
  const [parentTasks, setParentTasks] = useState([]);
  const { setSelectedParentTask, selectedParentTask } = props;
  const navigate = useNavigate();const editor = useRef(null);
  const [descv, setdesc] = useState("");

  const config = useMemo(() => ({
    readonly: false,
    placeholder: "Start typings...",
  }), []);

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescChange = (event) => {
    setdesc(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(Number(event.target.checked));
  };

  const handleParentTaskChange = (event) => {
    setSelectedParentTask(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${baseURL}api/tasks/AllTasks`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authtoken,
          },
        });
        const jsonData = await response.json();
        setParentTasks(jsonData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [authtoken]);

  const data = {
    title: titlev,
    description: descv,
    dueDate: datev,
    status: statusv,
    parentID: selectedParentTask, // Use selected parent task
  };

  const AddTaskhandler = async () => {
    try {
      const url = `${baseURL}api/tasks/Create`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": authtoken,
        },
        body: JSON.stringify(data),
      });
      const jsonData = await response.json();
      return jsonData._id;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    if (!titlev || !descv) {
      alert("Please fill in all required fields.");
      setLoading(false);
      return;
    }

    const taskId = await AddTaskhandler();

    try {
      // Step 1: Upload files iteratively
      const filePromises = Array.from(files).map(async (file) => {
        const fileData = new FormData();
        fileData.append("file", file);

        const fileUploadResponse = await fetch(`${baseURL}api/files/Upload`, {
          method: "POST",
          headers: {
            "Task-ID": taskId,
          },
          body: fileData,
        });

        if (!fileUploadResponse.ok) {
          throw new Error("Error uploading file");
        }
      });

      await Promise.all(filePromises);
    } catch (error) {
      console.error("Error:", error);
    }

    setLoading(false);
    if (taskId) {
      navigate("/");
    }
  };

  return (
    <>
      <div
        className={`col-12 container-ow ${
          Loading ? "" : "Collapsed"
        }  d-flex align-items-center justify-content-center`}
      >
        <div
          className={`spinner-border text-primary d-flex align-items-center justify-content-center`}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      <div className={`col-12 container-ow ${Loading ? "Collapsed" : ""}`}>
        <div className="MainTasksHead my-3 mx-2 container-ow d-flex justify-content-between align-items-center">
          <b>{selectedParentTask ? "Create Sub Note" : "Create Note"}</b>
        </div>
        <div>
          <form className="g-3 t-left text-dark">
            <div className="mx-3 my-3 font-14 Collapsed">
              <label htmlFor="ParentTask" className="form-label font-14">
                Parent Note
              </label>
              <select
                className="form-select addInput"
                aria-label="Default select example"
                id="SelectAddTask"
                value={selectedParentTask}
                onChange={handleParentTaskChange}
              >
                {parentTasks.map((task) => (
                  <option key={task._id} value={task._id}>
                    {task.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mx-3 my-3">
              <label htmlFor="Title" className="form-label font-14">
                Title
              </label>
              <input
                type="text"
                className="form-control addInput "
                id="Title"
                required
                value={titlev}
                onChange={handleTitleChange}
              />
            </div>
            <div className="mx-3 my-3">
              <label htmlFor="Description" className="form-label font-14">
                Description
              </label>
              <JoditEditor
                    ref={editor}
                    value={descv}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setdesc(newContent)} 
                    onChange={(newContent) => {}}
                  />
            </div>
            <div className="mx-3 my-3">
              <label htmlFor="DueDate" className="form-label font-14">
                DueDate
              </label>
              <input
                type="date"
                className="form-control addInput"
                id="DueDate"
                required
                value={datev}
                onChange={handleDateChange}
              />
            </div>
            <div class="form-check mx-3 my-3 checkbox-label-container">
              <label class="form-check-label font-14" style={{marginLeft: '-24px'}} for="flexCheckDefault">
                Completed:
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                style={{marginLeft: '12px'}}
                checked={statusv}
                onChange={handleStatusChange}
                id="flexCheckDefault"
              />
            </div>

            <div className="mx-3 my-3">
              <label htmlFor="File" className="form-label font-14">
                File
              </label>
              <input
                type="file"
                className="form-control addInput"
                id="File"
                onChange={handleFileChange}
                multiple
              />
            </div>
            <div className="col-12 my-3 d-flex justify-content-end">
              <button
                className="btn btn-success my-1 mx-3"
                onClick={handleSubmit}
              >
                Add Note
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
