
import React, { useEffect, useState } from 'react';
import TaskGrid from './TaskGrid';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Fileitem from './Fileitem';

export default function SubTasks() {
  const [tasks, setTasks] = useState([]);
  const baseURL = "https://taskongobackend.onrender.com/";
  const [Loading, setLoading] = useState(false);
  const [filesss, setFiles] = useState([]);
  const [parentTask, setParent] = useState([]);
  const State = useLocation().state; //For Parent NoteID 

  const containerStyle = {
    maxHeight: '100vh',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#333333 #222222',
  };
  const { TaskID } = useParams();

  const [titlev, setTitle] = useState("");
  const [currenttitlev, setcurrentTitle] = useState("");
  const [descv, setdesc] = useState("");
  const [datev, setDate] = useState("");
  const [statusv, setStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = `${baseURL}api/tasks/SubTasks`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'task-id': TaskID,
          },
        });

        const jsonData = await response.json();
        setTasks(jsonData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchParentData = async () => {
      try {
        const url = `${baseURL}api/tasks/GetTasks`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'task-id': TaskID,
          },
        });
        const jsonData = await response.json();
        setParent(jsonData);
        setTitle(jsonData[0].title);
        setcurrentTitle(jsonData[0].title);
        setdesc(jsonData[0].description);
        setStatus(jsonData[0].status);
        console.log(jsonData[0].dueDate);
        setDate((jsonData[0].dueDate) ? (new Date(jsonData[0].dueDate).toISOString().split('T')[0]) : "");

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchParentData();
  }, []);

  useEffect(() => {
    const fetchParentFiles = async () => {
      try {
        const url = `${baseURL}api/files/getallfiles`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'task-id': TaskID,
          },
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setFiles(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchParentFiles();
  }, []);

  const data = {
    title: titlev,
    description: descv,
    dueDate: datev,
    status: statusv,
    id: TaskID,
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const editTaskHandler = async () => {
    try {
      const url = `${baseURL}api/tasks/Edit`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      setcurrentTitle(data.title);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!titlev || !descv) {
      alert('Please fill in all required fields.');
      return; // Stop form submission if any required field is empty
    }
    editTaskHandler();
  };


  const handleDescChange = (event) => {
    setdesc(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(Number(event.target.value));
  };

  return (
    <>
    <div className={`col-9 container-ow ${Loading ? "" : "Collapsed"}  d-flex align-items-center justify-content-center`}>
        <div class={`spinner-border text-primary d-flex align-items-center justify-content-center`} role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div id="mainTasksContainer" className={`col-9 container-ow ${Loading ? "Collapsed" : ""}`} style={containerStyle}>
        <div className='MainTasksHead my-3 container-ow d-flex justify-content-between align-items-center'>
          <a
            href={(parentTask[0]) ? ((parentTask[0].parentID) ? (`/SubTasks/${parentTask[0].parentID}`) : "/") : ""}
            className="btn mx-3 d-flex align-items-center btn-outline-primary"
          >
            Go Back
          </a>
          <span className='mx-3 MainTasksHead'>SubTasks - {currenttitlev}</span>
          <Link className='btn btn-success mx-3' to='/Add' state={TaskID}>Add Sub Task</Link>
        </div>

        <div className='TextGridSticky'>
          <div className="container text-center ">
            <div className="row TextGridHeads">
              <div className="col-6">
                Title
              </div>
              <div className="col-4">
                Description
              </div>
              <div className="col-2">
                Control
              </div>
            </div>
          </div>
        </div>

        {/* Apply overflow-y: auto style to make only the mapped objects scrollable */}
        {tasks.length > 0 && (
          <div className="container text-center" style={{ overflowY: 'auto', maxHeight: 'calc(50vh - 140px)' }}>
            {tasks.map((task) => (
              <TaskGrid
                JSON={task}
                title={task.title}
                description={(task.description.length > 43) ? (`${task.description.slice(0, 42)}..`) : (task.description)}
              />
            ))}
          </div>
        )}
        <div className='SepLine' />
        <div style={{ width: '90%' }}>
          <div className='MainTasksHead my-3 mx-3 container-ow d-flex justify-content-between align-items-center'>
            Edit Task
          </div>
          <div>
            <form className='g-3 t-left text-light'>
              <div className='mx-3 my-3'>
                <label htmlFor='Title' className='form-label'>
                  Title
                </label>
                <input
                  type='text'
                  className='form-control addInput'
                  id='Title'
                  required // <- added required attribute
                  value={titlev}
                  onChange={handleTitleChange}
                />
              </div>
              <div className='mx-3 my-3'>
                <label htmlFor='Description' className='form-label'>
                  Description
                </label>
                <textarea
                  rows={1}
                  type='text'
                  className='form-control addInput'
                  id='Description'
                  required // <- added required attribute
                  value={descv}
                  onChange={handleDescChange}
                />
              </div>
              <div className='container container-ow'>
                <div className="row">
                  <div className=' my-2 mx-3 col-6'>
                    <label htmlFor='DueDate' className='form-label'>
                      DueDate
                    </label>
                    <input
                      type='date'
                      className='form-control addInput'
                      id='DueDate'
                      required // <- added required attribute
                      value={datev}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div className=' my-2 col-5'>
                    <label className='form-label'>Status</label>
                    <select
                      className='form-select addInput'
                      aria-label='Default select example'
                      required // <- added required attribute
                      value={statusv}
                      onChange={handleStatusChange}
                    >
                      <option value='0'>Pending</option>
                      <option value='1'>Completed</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='mx-3 my-3'>
                {filesss.length > 0 && (
                  <div>
                    {filesss.map((task) => (
                      <Fileitem
                        JSON={task}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className='col-12 my-2'>
                <button className='btn btn-primary mx-3' type='submit' onClick={handleSubmit}>
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

