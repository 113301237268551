import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainTasks from './Components/MainTasks';
import './App.css'
import AddTask from './Components/AddTask';
import SubTasks from './Components/SubTasks';
import SignUp from './Components/SignUp';
import OTP from './Components/OTP'
import Login from './Components/Login';
import SearchRes from './Components/SearchRes';

function App() {
  return (
    <div className="text-center container-ow">
      <div className="row row-ow">
        <Router>
          <Routes>
            <Route path="/" element={<><MainTasks /></>} />
            <Route path="/Add" element={<><AddTask /></>} />
            <Route path="/SubTasks/:TaskID" element={<><SubTasks/></>} />
            <Route path="/SignUp" element={<SignUp/>} />
            <Route path="/Login" element={<Login/>} />
            <Route path="/OTP" element={<OTP/>} />
            <Route path="/Search" element={<><SearchRes/></>} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
