import React, { useState, useEffect } from "react";

function TaskGrid(props) {
  const {
    JSON: TaskJSON,
    title,
    isListOpen,
    toggleList,
    selectedTaskId,
    setListOpen,
  } = props;
  const TaskID = TaskJSON._id;
  

  const [loading, setLoading] = useState(false);
  const [listPosition, setListPosition] = useState({ top: 0, left: 0 });
  const baseURL = "https://taskongobackend.onrender.com/";

  const HideView = () => {
    setListOpen(false);
  };

  const handleListToggle = (taskId) => {
    console.log(`ID TEST`);
    console.log(taskId);
    console.log(selectedTaskId);
    if (isListOpen && taskId === selectedTaskId) {
      setListOpen(!isListOpen);
      toggleList(taskId);
    } else {
      const buttonRect = document
        .getElementById(`ListButton${TaskID}`)
        .getBoundingClientRect();
      setListPosition({
        top: buttonRect.bottom,
        left: buttonRect.left,
      });
      toggleList(taskId);
      if (!isListOpen) {
        setListOpen(!isListOpen);
      }
    }
  };

  return (
    <>
      <div className="mx-3">
        <div
          className={`container text-center ${
            loading ? "" : "Collapsed"
          }  d-flex align-items-center justify-content-center`}
        >
          <span>Loading...</span>
        </div>
        <div className={`container ${loading ? "Collapsed" : ""} text-center`}>
          <div className="row d-flex align-items-center">
            <div className="TaskGridCol">
              <label
                id={`Text${TaskJSON._id}`}
                style={{
                  width: "70%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                onClick={HideView}
              >
                {title}
              </label>
              <button
                id={`ListButton${TaskID}`}
                className="Button-options ListButton"
                onClick={() => handleListToggle(TaskID)}
              >
                <span class="material-symbols-outlined">more_vert</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskGrid;
