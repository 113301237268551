import React, { useEffect, useState } from 'react';
import TaskGrid from './TaskGrid';
import { Link, json, useLocation } from 'react-router-dom';



export default function SearchRes() {
  const authtoken = localStorage.getItem('tog-auth-token');
  const [Loading, setLoading] = useState(false);
  const state = useLocation().state
    const baseURL = "https://taskongobackend.onrender.com/";
    const [tasks, setTasks] = useState([]);
  const containerStyle = {
    maxHeight: '100vh',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#333333 #222222',
  };

  useEffect(() => {
    // Do something with the updated state (SearchQuery, DateQuery, SortQuery)
    const fetchData = async () => {
      setLoading(true);
      try {
        console.log(authtoken);
        const url = `${baseURL}api/tasks/Filters`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': authtoken,
            'SearchQ': state.SearchQuery,
            'DateQ': state.DateQuery,
            'SortQ': state.SortQuery,
          },
        });
        const jsonData = await response.json();
        console.log(jsonData);
        setTasks(jsonData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [state.SearchQuery, state.DateQuery, state.SortQuery]);

 

  return (
    <>
    <div className={`col-9 container-ow ${Loading ? "" : "Collapsed"}  d-flex align-items-center justify-content-center`}>
        <div class={`spinner-border text-primary d-flex align-items-center justify-content-center`} role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div id="mainTasksContainer" className={`col-9 container-ow ${Loading ? "Collapsed" : ""}`} style={containerStyle}>
        <div className='MainTasksHead my-3 container-ow d-flex justify-content-between align-items-center'>
          <span className='mx-3 MainTasksHead'>Tasks:</span>
          <Link className='btn btn-success mx-3' to='/Add' >Add Task</Link>
        </div>

        <div className='TextGridSticky'>
          <div className="container text-center ">
            <div className="row TextGridHeads">
              <div className="col-6">
                Title
              </div>
              <div className="col-4">
                Description
              </div>
              <div className="col-2">
                Control
              </div>
            </div>
          </div>
        </div>

        {/* Apply overflow-y: auto style to make only the mapped objects scrollable */}
        {tasks.length > 0 && (
          <div className="container text-center" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 140px)' }}>
            {tasks.map((task) => (
              <TaskGrid
                JSON={task}
                title={task.title}
                description={(task.description.length > 43) ? (`${task.description.slice(0, 42)}..`) : (task.description)}
              />
            ))}
          </div>
        )}
        </div>
    </>
  );
}
