import React from 'react'

export default function Fileitem(props) {
    const baseURL = "https://taskongobackend.onrender.com/";
    const elementJSON = props.JSON;
  return (
    <div>
        <a href={`${baseURL}api/files/getfile/${elementJSON._id}`}>{elementJSON.fileName}</a>
    </div>
  )
}
