import React, { useEffect, useMemo, useRef, useState } from "react";
import TaskGrid from "./TaskGrid"; // Assuming TaskGrid component is in './TaskGrid'
import ReactDOM from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import Fileitem from "./Fileitem";
import AddTask from "./AddTask";
import JoditEditor from "jodit-react";

export default function MainTasks() {
  const authtoken = localStorage.getItem("tog-auth-token");
  const [CopiedTask, setCopiedTask] = useState([]);
  const [isCut, setisCut] = useState(false);
  const [isCopyEN, setisCopyEN] = useState(false);
  const [filesss, setFiles] = useState([]);
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const baseURL = "https://taskongobackend.onrender.com/";
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [isListOpen, setListOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [selectedParentTask, setSelectedParentTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [AddTaskId, setAddTaskId] = useState(null);
  const [titlev, setTitle] = useState("");
  const [currenttitlev, setcurrentTitle] = useState("");
  const [datev, setDate] = useState("");
  const [statusv, setStatus] = useState(false);
  const [addView, setaddView] = useState(false);
  const [currTaskID, setID] = useState("");
  const navigate = useNavigate();
  if (!authtoken) {
    console.log("If entered");
    navigate("/SignUp");
  }
  const editor = useRef(null);
  const [descv, setdesc] = useState("");

  const config = useMemo(() => ({
    readonly: false,
    placeholder: "Start typing...",
    buttons: "bold,italic,underline,strikethrough,|,superscript,subscript,|,ul,ol,|,indent,outdent,|,font,fontsize,brush,paragraph,|,align,undo,redo",
    sizeLG: "12pt", 
    defaultLineHeight:0,
    removeButtons: ["source", "table", "about"], 
    showCharsCounter: false, 
    showWordsCounter: false, 
    showXPathInStatusbar: false,
    showPath: false, 
    removeAbout: true, 
    minHeight: 300, 
  }), []);
  

  const fetchParentFiles = async (TaskID) => {
    try {
      const url = `${baseURL}api/files/getallfiles`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "task-id": TaskID,
        },
      });
      const jsonData = await response.json();
      console.log(jsonData);
      setFiles(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("tog-auth-token");
    navigate("/SignUp");
  };
  const containerStyle = {
    height: "88vh",
    maxHeight: "88vh",
    scrollbarWidth: "thin",
    scrollbarColor: "#333333 #222222",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${baseURL}api/tasks/AllTasks`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "auth-token": authtoken,
          },
        });
        const jsonData = await response.json();
        setTasks(jsonData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchParentData = async (TaskID) => {
    try {
      const url = `${baseURL}api/tasks/GetTasks`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "task-id": TaskID,
        },
      });
      const jsonData = await response.json();
      setTitle(jsonData[0].title);
      setcurrentTitle(jsonData[0].title);
      setdesc(jsonData[0].description);
      setStatus(jsonData[0].status);
      setID(jsonData[0]._id);
      console.log(jsonData[0].dueDate);
      setDate(
        jsonData[0].dueDate
          ? new Date(jsonData[0].dueDate).toISOString().split("T")[0]
          : ""
      );
      console.log(`Title: ${titlev} desc: ${descv}`);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSubTasks = async () => {
    try {
      const url = `${baseURL}api/tasks/AllTasks`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": authtoken,
        },
      });

      const jsonData = await response.json();
      console.log(jsonData);
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescChange = (event) => {
    setdesc(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const HandleLocations = async (event) => {
    try {
      const taskId = selectedTaskId;

      const direction = event.currentTarget.id;
      if (!taskId) {
        console.error("Task ID is null or undefined.");
        return;
      }

      const url = `${baseURL}api/tasks/${direction}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          taskID: selectedTaskId,
        }),
      });

      const jsonData = await response.json();
      console.log(jsonData);
      console.log(tasks);
      const taskIndex = tasks.findIndex((task) => task._id === selectedTaskId);
      console.log(`WOWOOWOWOWO`);
      console.log(taskIndex);

      const url1 = `${baseURL}api/tasks/AllTasks`;
      const response1 = await fetch(url1, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": authtoken,
        },
      });
      const jsonData1 = await response1.json();
      setTasks(jsonData1);
      setListOpen(false);
    } catch (error) {
      console.error("Error Deleting:", error);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.checked);
    console.log(event.currentTarget.checked);
  };
  const newMainHandler = () => {
    setSelectedParentTask(null);
    const InputElement = document.getElementById("SelectAddTask");
    InputElement.value = null;
    setaddView(true);
  };
  const newSubHandler = () => {
    setSelectedParentTask(selectedTaskId);
    setaddView(true);
    setListOpen(false);
  };
  const DeleteTaskhandle = async () => {
    try {
      const url = `${baseURL}api/tasks/Delete`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          taskID: selectedTaskId,
        }),
      });
      const jsonData = await response.json();
      console.log(jsonData);
      window.location.reload();
    } catch (error) {
      console.error("Error Deleting:", error);
    }
  };

  const editTaskHandler = async () => {
    const data = {
      title: titlev,
      description: descv,
      dueDate: datev,
      status: statusv,
      id: currTaskID,
    };
    try {
      const url = `${baseURL}api/tasks/Edit`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const jsonData = await response.json();
      setcurrentTitle(data.title);
      const titleElement = document.getElementById(`Text${currTaskID}`);
      if (titleElement) {
        titleElement.textContent = titlev;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!titlev || !descv) {
      alert("Please fill in all required fields.");
      return; // Stop form submission if any required field is empty
    }
    editTaskHandler();
  };
  // ...

  const toggleList = (taskId) => {
    setSelectedTaskId(taskId); // Remember the last clicked task ID

    // No need to set dropdown position here
  };

  useEffect(() => {
    if (isListOpen && selectedTaskId) {
      const buttonElement = document.getElementById(
        `ListButton${selectedTaskId}`
      );
      if (buttonElement) {
        const buttonRect = buttonElement.getBoundingClientRect();
        setDropdownPosition({
          top: buttonRect.bottom,
          left: buttonRect.left,
        });
      }
    }
  }, [isListOpen, selectedTaskId]);

  const copy = async () => {
    try {
      const url = `${baseURL}api/tasks/GetTasks`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "task-id": selectedTaskId,
        },
      });
      const jsonData = await response.json();
      setCopiedTask(jsonData[0]);
      setisCut(false);
      setisCopyEN(true);
      setListOpen(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const cut = async () => {
    try {
      const url = `${baseURL}api/tasks/GetTasks`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "task-id": selectedTaskId,
        },
      });
      const jsonData = await response.json();
      setCopiedTask(jsonData[0]);
      setisCopyEN(true);
      setisCut(true);
      setListOpen(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const addDynamicTasks = async (event, TaskID, TaskTitle) => {
    setaddView(false);
    const testDiv = document.getElementById("TaskTree");
    const containerId = event.currentTarget.id;

    const divsToChange = document.querySelectorAll(".TaskGridSelector");
    divsToChange.forEach((div) => {
      if (div.id === containerId) {
        div.style.color = "black";
      }
    });

    event.currentTarget.style.color = "#0070C0";
    console.log(`Target${event.currentTarget.id}`);
    const currentId = parseInt(event.currentTarget.id, 10);
    console.log(`Current ID: ${currentId}`);
    const index = currentId + 1;
    setNote1(TaskTitle);
    console.log(`index: ${index}`);

    if (testDiv) {
      const newDiv = document.createElement("div");
      newDiv.classList.add("container", "container-ow", "col-3", "text-center");
      newDiv.id = `DivN${index}`;

      try {
        // Clear existing divs with IDs starting from current index
        for (let i = currentId + 1; i >= currentId; i++) {
          const extraDiv = document.getElementById(`DivN${i}`);
          if (extraDiv) {
            extraDiv.remove();
          } else {
            break;
          }
        }

        const subTasks = await getSubTasks();
        setTasks(subTasks);
        console.log(TaskID);
        console.log(subTasks);

        if (subTasks.length > 0) {
          const newTasksContainer = (
            <div style={{ overflowY: "auto", maxHeight: "88vh" }}>
              {tasks.map((task) =>
                task.parentID === TaskID ? (
                  <div
                    className="TaskGridSelector"
                    style={{ overflowY: "auto" }}
                    id={index}
                    onClick={(event) => {
                      addDynamicTasks(event, task._id, task.title);
                      fetchParentData(task._id);
                      fetchParentFiles(task._id);
                    }}
                  >
                    <TaskGrid
                      JSON={task}
                      title={task.title}
                      isListOpen={isListOpen}
                      selectedTaskId={selectedTaskId}
                      toggleList={toggleList}
                      setListOpen={setListOpen}
                    />
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
          );
          console.log(newTasksContainer);
          // Convert JSX to HTML using ReactDOM
          ReactDOM.render(newTasksContainer, newDiv);
          testDiv.appendChild(newDiv);
        }
      } catch (error) {
        console.error("Error fetching subtasks:", error);
      }
    }
  };

  const paste = async () => {
    try {
      const {
        index,
        _id,
        userID,
        DateOfCreation,
        DateOfUpdate,
        parentID,
        ...newTask
      } = CopiedTask;

      newTask.parentID = selectedTaskId;
      const url = `${baseURL}api/tasks/Create`;

      // Copy and paste the main task
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": authtoken,
        },
        body: JSON.stringify(newTask),
      });

      const jsonData = await response.json();
      const newTaskId = jsonData._id;

      // If it's a cut operation, delete the original task
      if (isCut) {
        const deleteUrl = `${baseURL}api/tasks/Delete`;
        await fetch(deleteUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            taskID: CopiedTask._id,
          }),
        });
        setCopiedTask([]);
      }

      // Copy and paste child tasks recursively
      const copyChildren = async (originalParentId, newParentId) => {
        const childrenUrl = `${baseURL}api/tasks/SubTasks`;
        const childrenResponse = await fetch(childrenUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "task-id": originalParentId,
          },
        });

        const childrenData = await childrenResponse.json();

        if (Array.isArray(childrenData)) {
          for (const child of childrenData) {
            const { _id: childId, ...childData } = child;
            childData.parentID = newParentId;

            const childResponse = await fetch(`${baseURL}api/tasks/Create`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "auth-token": authtoken,
              },
              body: JSON.stringify(childData),
            });

            const childJsonData = await childResponse.json();
            const newChildId = childJsonData._id;

            // Recursively copy children of this child
            await copyChildren(childId, newChildId);
          }
        }
      };

      await copyChildren(_id, newTaskId);

      // Update the tasks list
      const updatedTasksUrl = `${baseURL}api/tasks/AllTasks`;
      const updatedTasksResponse = await fetch(updatedTasksUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": authtoken,
        },
      });

      const updatedTasksData = await updatedTasksResponse.json();
      setTasks(updatedTasksData);
      setListOpen(false);

      return newTaskId;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  return (
    <>
      <div
        className={`col-12 container-ow ${
          Loading ? "" : "Collapsed"
        }  d-flex align-items-center justify-content-center`}
      >
        <div
          className={`spinner-border text-primary d-flex align-items-center justify-content-center`}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      <div className="col-12 container-ow HeaderDiv py-3 d-flex align-items-center justify-content-between">
        {isListOpen && (
          <div
            className="list-container"
            style={{
              position: "absolute",
              top:
                window.innerHeight / 2 > dropdownPosition.top
                  ? `${dropdownPosition.top - 24}px`
                  : `${dropdownPosition.top - 300}px`,
              left: `${dropdownPosition.left - 124}px`,
            }}
          >
            <div>
              <button
                className="list-container-button"
                onClick={DeleteTaskhandle}
              >
                Delete
              </button>
            </div>
            <div>
              <button className="list-container-button" onClick={newSubHandler}>
                New Task
              </button>
            </div>
            <div>
              <button className="list-container-button" onClick={copy}>
                Copy
              </button>
            </div>
            <div>
              <button className="list-container-button" onClick={cut}>
                Cut
              </button>
            </div>
            <div>
              <button className="list-container-button" onClick={paste}>
                Paste
              </button>
            </div>
            <div>
              <button
                className="list-container-button"
                id="MoveAbove"
                onClick={HandleLocations}
              >
                Move Above
              </button>
            </div>
            <div>
              <button
                className="list-container-button"
                id="MoveBelow"
                onClick={HandleLocations}
              >
                Move Below
              </button>
            </div>
            <div>
              <button
                className="list-container-button"
                id="MoveTop"
                onClick={HandleLocations}
              >
                Move to Top
              </button>
            </div>
            <div>
              <button
                className="list-container-button"
                id="MoveBottom"
                onClick={HandleLocations}
              >
                Move to Bottom
              </button>
            </div>
          </div>
        )}
        <button
          onClick={newMainHandler}
          type="button"
          className="btn mx-3 btn-success"
        >
          Add Note
        </button>
        <span className="MainHeadText">TaskOnGo</span>
        <button
          type="button"
          onClick={handleLogout}
          className="btn mx-3 btn-danger"
        >
          Logout
        </button>
      </div>
      <div className="row d-flex">
        <div
          id="mainTasksContainer"
          className={`col-7 container-ow  ${Loading ? "Collapsed" : ""}`}
          style={{
            background: '#f4f6f8',
            height: "88vh",
            maxHeight: "88vh",
            scrollbarWidth: "thin",
            scrollbarColor: "#333333 #222222",
          }}
        >
          <div id="TaskTree" style={{ overflow: "auto" }}>
            {tasks.length > 0 && (
              <div
                className="container col-3 container-ow text-center"
                style={{ overflowY: "auto", maxHeight: "88vh" }}
              >
                {tasks.map((task) =>
                  !task.parentID ? (
                    <div
                      className="TaskGridSelector"
                      id={1}
                      onClick={(event) => {
                        addDynamicTasks(event, task._id, task.title);
                        fetchParentFiles(task._id);
                        fetchParentData(task._id);
                      }}
                    >
                      <TaskGrid
                        JSON={task}
                        title={task.title}
                        isListOpen={isListOpen}
                        selectedTaskId={selectedTaskId}
                        toggleList={toggleList}
                        setListOpen={setListOpen}
                      />
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            )}
          </div>
        </div>
        <div
          style={{ overflowY: "auto", borderLeft: "1px solid #e0e3e6", maxHeight: '88vh' }}
          className={`col-5 ${addView ? "Collapsed" : ""}`}
        >
          <div>
            <div>
              <div className="MainTasksHead my-2 mx-3" style={{display: 'flex'}}>
                <b>Note:</b>
                <span style={{marginLeft: '14px'}}>{note1}</span>
              </div>
            </div>
            <div>
              <form className="g-3 t-left text-dark font-14">
                <div className="mx-3 my-3" style={{display: 'flex', alignItems: 'center'}}>
                  <label htmlFor="Title" className="form-label" style={{margin: 0}}>
                    <b>Title:</b>
                  </label>
                  <input
                    type="text"
                    className="form-control addInput"
                    id="Title"
                    value={titlev}
                    onChange={handleTitleChange}
                    style={{
                      marginLeft: '14px'
                    }}
                    required
                  />
                </div>
                <div className="mx-3 my-3">
                  <label htmlFor="Description" className="form-label">
                    <b>Description</b>
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={descv}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setdesc(newContent)} 
                    onChange={(newContent) => {}}
                  />
                </div>
                <div className="container container-ow">
                  {/* ... (previous code) */}
                  <div className=" my-2 mx-3 col-6" style={{display: 'flex', alignItems: 'center'}}>
                    <label htmlFor="DueDate" className="form-label" style={{margin: '0'}}>
                      <b>DueDate</b>
                    </label>
                    <input
                      type="date"
                      className="form-control addInput"
                      id="DueDate"
                      value={datev}
                      onChange={handleDateChange}
                      style={{marginLeft: '14px'}}
                      required
                    />
                  </div>

                  <div class="form-check mx-3 my-3 checkbox-label-container">
                    <label
                      class="form-check-label font-14"
                      style={{ marginLeft: "-24px" }}
                      for="flexCheckDefault"
                    >
                      Completed:
                    </label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      style={{ marginLeft: "12px" }}
                      checked={statusv}
                      onChange={handleStatusChange}
                      id="flexCheckDefault"
                    />
                  </div>
                </div>
                <div className="mx-3 my-3">
                  {filesss.length > 0 && (
                    <div>
                      {filesss.map((task) => (
                        <Fileitem JSON={task} />
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-12 my-3 d-flex justify-content-end">
                  <button
                    className="btn btn-success my-3 mx-3"
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          style={{
            overflowY: "auto",
            borderLeft: "1px solid #e0e3e6",
            maxHeight: "88vh",
          }}
          className={`col-5 ${addView ? "" : "Collapsed"}`}
        >
          <AddTask
            setSelectedParentTask={setSelectedParentTask}
            selectedParentTask={selectedParentTask}
          />
        </div>
      </div>
    </>
  );
}
